<template>
  <div class="mt-3">
    <div class="container p-4">
      <div class="row">
        <div class="mb-4 col-lg-6 col-md-6 mb-md-0">
          <div class="mx-auto card crd" style="">
            <div class="card-body">
              <img class="img-fluid" src="../../assets/logo-icon/elektirikLogo_.png" />

              <ul class="mb-0 list-unstyled cardList">
                <li>
                  <i class="mb-0 fas fa-phone">
                    <a class="m-3 telNo" href="tel:+90-542-802-46-30">542-802-46-30</a>
                  </i>
                  <i class="mb-0 fas fa-phone">
                    <a class="m-3 telNo" href="tel:+90-538-239-39-96">538-239-39-96</a>
                  </i>
                </li>
                <li>
                  <i class="mb-0 fas fa-map-marker-alt">
                    <a
                      href="https://goo.gl/maps/sFMXZeiMQQA9p9CM6"
                      target="_blank"
                      class="m-3 telNo"
                    >
                      Ahatlı mahallesi Ulusoy Cad. 96/A Kepez/Antalya
                    </a>
                  </i>
                </li>

                <li>
                  <i class="mb-0 fas fa-envelope">
                    <a class="m-3 telNo" href="mailto:nobetciteknikservis07@gmail.com">
                      nobetciteknikservis07@gmail.com
                    </a>
                  </i>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mb-4 col-lg-6 col-md-6 mb-md-0">
          <h2>Hakkımızda</h2>

          <p>
            Nöbetçi Teknik Servisimiz Antalya'nın her yerinde evinizin ve iş yerinizin her türlü
            teknik ihtiyacında <u>7/24 kesintisiz hizmet vermektedir.. </u>
          </p>
        </div>
      </div>
    </div>

    <div class="p-3 text-center" style="background-color: rgba(0, 0, 0, 0.2);">
      © 2021 Copyright:
      <a
        style="text-decoration:none;color:black;"
        href="https://www.salihgumus.xyz/"
        target="_blank"
        >Muhammed Salih Gümüş & Kaan Berke Yılmaz</a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.crd {
  border-top: 5px solid red;
  -webkit-box-shadow: -1px 3px 49px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 3px 49px -13px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 3px 49px -13px rgba(0, 0, 0, 0.75);
  white-space: nowrap;
  width: 30rem;
  padding: 30px;
}
.cardList {
  font-size: 18px;
  color: #808080;
  padding-top: 35px;
}
.telNo {
  color: #808080 !important;
  text-decoration: none;
  font-weight: 400 !important;
}

@media only screen and (max-width: 1180px) {
  .crd {
    width: 20rem;
  }
  .cardList {
    white-space: normal;
  }
}
</style>
