<template>
  <div class="topSection">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark nvbr">
      <div class="container-fluid">
        <ul class="mx-auto navbar-nav">
          <li class="nav-item ">
            <router-link :to="{ name: 'Home' }" class="nav-link active">
              Anasayfa
            </router-link>
          </li>
          <!-- <li class="nav-item ">
            <router-link :to="{ name: 'Works', params: { id: '0' } }" class="nav-link active">
              İşlerimiz
            </router-link>
          </li> -->
          <li>
            <router-link :to="{ name: 'Klima' }" class="nav-link active">
              Klima
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'About' }" class="nav-link active">
              Bize ulaşın
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
    <!--Slider-->
    <div class="slider" v-if="isSliderOn">
      <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active ">
            <img src="../../assets/dukkan/3.jpeg" class="d-block w-100" />
          </div>

          <div class="carousel-item">
            <img src="../../assets/dukkan/1.jpeg" class="d-block w-100" />
          </div>
          <div class="carousel-item">
            <img src="../../assets/dukkan/5.jpeg" class="d-block w-100" />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <!--Slider end-->
  </div>
</template>

<script>
export default {
  props: ['isSliderOn'],
};
</script>

<style>
.topSection {
  margin-top: 25px;
}
.nvbr {
  background-color: #ed1c24 !important;
  width: 70%;
  border-radius: 25px;
  margin-right: auto !important;
  margin-left: auto !important;
  margin-top: 25px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
}

.slider {
  position: relative;
}
.carousel-inner img {
  height: 700px;
}

@media only screen and (max-width: 500px) {
  .carousel-inner img {
    height: 350px !important;
  }
}
</style>
